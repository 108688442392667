<template>
  <v-card
    @click.passive="emitHelpMode"
    @focus.capture="emitHelpMode"
    align-start
    class="default"
    height="100%"
    elevation="1"
  >
      <v-card-text class="px-0 pb-0">
        <v-select
            @input="$emit('input', $event)"
            @change="$emit('modified', $event)"
            :return-object="returnObject"
            persistent-hint
            :no-data-text="$t('noData')"
            :error-messages="
                errors.collect(
                    formScope + '.' + fieldName + rowId
                )
            "
            :data-vv-name="fieldName + rowId"
            :data-vv-scope="formScope"
            v-validate="required ? 'required' : ''"
            :disabled="loading || disabled || disabledComp"
            v-bind="$props"
            background-color="transparent"
        >
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
            <template v-slot:append-outer>
                <v-tooltip v-if="!helpMode && companyGroupHelp && companyGroupHelp.fieldIcon" bottom color="primary">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    icon
                    large
                    color="primary"
                    class="mr-3"
                    @click="setHelpMode(true)"
                    >
                    <v-icon v-text="companyGroupHelp.fieldIcon" />
                    </v-btn>
                </template>
                    {{ $t('furtherInformation') }}
                </v-tooltip>
            </template>
        </v-select>
      </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
const MAIN_ENTITY = 'App\\Entity\\ProcessingActivity\\ProcessingActivity';

export default {
    name: 'LeaSelect',
    inject: {
        $validator: '$validator',
    },
    data () {
        return {
            disabledComp: false
        }
    },
    computed: {
        ...mapGetters({
            helpMode: 'processingActivityModel/getHelpModeState',
            getCompanyHelp: 'companyGroupHelp/getByEntityAndFieldName',
        }),
        companyGroupHelp() {
            return this.getCompanyHelp(MAIN_ENTITY, this.fieldName, this.childFieldName);
        },
    },
    methods: {
        ...mapActions({
            setHelpMode: 'processingActivityModel/setHelpModeState',
        }),
        disable () {
            this.disabledComp = true;
        },
        enable () {
            this.disabledComp = false;
        },
        emitHelpMode () {
            this.$emit('help-mode', {
                title: this.label,
                text: this.helpModeText,
                fieldName: this.fieldName,
                childFieldName: this.childFieldName
            })
        }
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        value: {
            type: [Number, String],
            default: null,
        },
        items: {
          type: Array,
          default: () => []
        },
        itemValue: {
            type: String,
            default: 'id',
        },
        itemText: {
            type: String,
            default: 'title',
        },
        itemColor: {
            type: String,
            default: 'primary',
        },
        color: {
            type: String,
            default: undefined,
        },
        backgroundColor: {
            type: String,
            default: undefined,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        deletableChips: {
            type: Boolean,
            default: false,
        },
        chips: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        returnObject: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        disabled (val) {
            this.disabledComp = val;
        }
    },
    created () {
        this.$emit('created');
    }
};
</script>
