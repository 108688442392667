<template>
    <v-card
        @click.passive="emitHelpMode"
        @focus.capture="emitHelpMode"
        align-start
        height="100%"
        elevation="1"
    >
        <v-card-text class="pa-0">
            <vue-dropzone
                :ref="'myVueDropzone' + i"
                :id="'dropzone' + i"
                :options="dropzoneOptions"
                v-model="model"
                v-on:vdropzone-sending="sendFile"
                v-on:vdropzone-success="addUpload"
                v-on:vdropzone-removed-file="removeUpload"
            >
                ></vue-dropzone
            >
            <div
                class="v-messages v-messages--top-border theme--light pl-2 pb-2"
            >
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                        {{ hint }}
                    </div>
                </div>
            </div>
            <v-list v-if="model.length">
                <v-list-item
                    v-for="file in model"
                    :key="file.uuid"
                    @click="$getFile(`/api/enclosures/download/${file.uuid}`, file.filename, true)"
                    :disabled="$wait.is('fetching file')"
                >
                    <v-list-item-action>
                        <v-icon color="primary">cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="file.filename"
                        ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-tooltip left color="error">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    @click="deleteItem(file)"
                                    color="error"
                                    icon
                                    :disabled="disabled"
                                >
                                <v-icon
                                    color="error"
                                    >mdi-close</v-icon
                                >
                                </v-btn>
                            </template>
                            <span>{{ removeHint }}</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import { getToken } from '@/utils/auth';

export default {
    components: {
        vueDropzone,
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
          type: Array,
          default: () => []
        },
        i: {
            type: Number,
            default: null,
        },
        removeHint: {
            type: String,
            default: null,
        },
        enclosureType: {
            type: String,
            default: 'ENCLOSURE',
        },
    },
    data() {
        return {
            model: this.value || [],
            dropzoneOptions: null,
            disabledComp: false
        };
    },
    created() {
        const uploadHeaders = {
            'Cache-Control': null,
            'X-Requested-With': null,
            Accept: `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`
        }

        if (process.env.VUE_APP_SSO !== 'true') {
            uploadHeaders.Authorization = `Bearer ${getToken()}`
        }

        this.dropzoneOptions = {
            headers: uploadHeaders,
            dictDefaultMessage: this.label,
            addRemoveLinks: true,
            url: `${process.env.VUE_APP_BASE_URL}/api/enclosures/upload`,
            thumbnailHeight: 120,
            createImageThumbnails: false,
            maxFilesize: 20,
            acceptedFiles:
                'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptm,.pptx',
        };
    },
    mounted () {
        if (this.disabled || this.disabledComp) {
            this.$refs['myVueDropzone' + this.i].disable();
        }
    },
    methods: {
        disable () {
            this.$refs['myVueDropzone' + this.i].disable();
        },
        enable () {
            this.$refs['myVueDropzone' + this.i].enable();
        },
        deleteItem(file) {
            this.model.splice(this.model.indexOf(file), 1);
        },
        sendFile(file, xhr, formData) {
            formData.append('clientId', file.upload.uuid);
            formData.append('type', this.enclosureType);
        },
        addUpload(file, response) {
            this.model.unshift({ ...file.upload, ...response });
            this.$emit('modified');
            this.$refs['myVueDropzone' + this.i].removeFile(file);
        },
        removeUpload() {
            this.$emit('modified');
        },
        emitHelpMode () {
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.label,
                    text: this.helpModeText,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            } else {
                this.$emit('help-mode', {
                    step: this.step,
                    name: this.fieldName,
                    group: this.groupName,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    },
    watch: {
        model(val) {
            this.$emit('input', val);
            this.$emit('modified', val);
        },
    },
};
</script>

<style lang="scss" scoped>
.dropzone {
    min-height: 120px;
    border-width: 0;
}
</style>
