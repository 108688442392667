<template>
  <div class="px-4 py-4" :class="{'pb-6': expanded, 'pb-3': !expanded}" style="min-height:72px;">
      <div class="d-inline-block mr-3">
          <p class="mb-0 text-subtitle-1 font-weight-regular mr-3" v-text="headline" />
          <p v-if="subheadline" class="mb-0 caption font-weight-regular" v-text="subheadline" />
      </div>
      <v-tooltip
          right
          :bottom="$vuetify.breakpoint.smAndDown"
          :color="disabled ? 'grey' : 'success'"
          :disabled="!disabled && $vuetify.breakpoint.smAndDown"
          :class="{
              'd-inline-block va-bottom': $vuetify.breakpoint.mdAndUp,
          }"
      >
          <template v-slot:activator="{ on }">
              <div v-on="on" class="d-inline-block mb-0" :class="{'mt-3 d-block': $vuetify.breakpoint.smAndDown}">
                  <v-btn
                      :disabled="disabled"
                      color="success"
                      small
                      depressed
                      :tile="$vuetify.breakpoint.smAndDown"
                      :fab="$vuetify.breakpoint.mdAndUp"
                      :block="$vuetify.breakpoint.smAndDown"
                      @click="$emit('click:add', $event)"
                      :class="{ 'va-bottom': subheadline}"
                  >
                      <v-icon medium>add</v-icon>
                      <span
                          v-if="$vuetify.breakpoint.smAndDown"
                          v-text="buttonText"
                      ></span>
                  </v-btn>
              </div>
          </template>
          <template>
              <span
                  v-if="disabled"
                  v-text="disabledText"
              ></span>
              <span
                  v-else-if="$vuetify.breakpoint.mdAndUp"
                  v-text="buttonText"
              ></span>
          </template>
      </v-tooltip>
  </div>
</template>

<script>
export default {
    name: 'ProcessingActivityRowHeading',
    props: {
      headline: {
        type: String,
        default: 'headline'
      },
      subheadline: {
        type: String,
        default: null
      },
      buttonText: {
        type: String,
        default: 'button-text'
      },
      disabledText: {
        type: String,
        default: function () {
          return this.$t('notAuthorizedAction')
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      expanded: {
        type: Boolean,
        default: false
      }
    }
}
</script>