<template>
    <v-container fluid class="pl-1 pt-0 pb-3 mr-n1" :class="{'mb-3': !isCollapsed && lastIndex !== i, 'mt-3': !isCollapsed && i > 0}">
        <v-row class="mb-1 elevation-1">
            <v-toolbar short flat color="tertiary" class="cursor-pointer"
                @click="collapseItem(isCollapsed ? 'show' : 'hide', i)"
            >
                <v-toolbar-title class="pl-0 ml-n2 text-subtitle-1">
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-if="isCollapsed" v-text="'mdi-unfold-more-horizontal'" />
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-else v-text="'mdi-unfold-less-horizontal'" />
                    <span
                        class="v-toolbar-title-text pl-2"
                        :class="{
                            'error--text': hasError,
                        }"
                        >{{ $t('vvtProcessEditor.deletionPractise.headlineItem') }}
                        {{ i + 1 }}
                        <template v-if="groupName">: {{ groupName }}</template>
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip v-if="hasError" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="error" class="ma-2"
                            >warning</v-icon
                        >
                    </template>
                    <span v-html="$t('error')"></span>
                </v-tooltip>
                <v-tooltip v-if="i > 0" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('up')"
                        >
                            <v-icon>keyboard_arrow_up</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveUp'" />
                </v-tooltip>
                <v-tooltip v-if="i < lastIndex" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('down')"
                        >
                            <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveDown'" />
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="primary"
                            @click.stop="commentMode = true"
                            v-on="on"
                            small
                            fab
                            depressed
                            :outlined="!hasComments"
                            :disabled="!hasComments && !canEdit"
                        >
                            <v-icon
                                small
                                :color="hasComments ? 'white' : 'primary'"
                                >mdi-comment-outline</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'comments.buttonLabel'"></span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            class="mx-2"
                            color="success"
                            @click.stop="duplicateItem()"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>js-icon-copy</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'duplicate'" />
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            @click.stop="removeItemConfirmation = true"
                            class="mx-2"
                            color="error"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>clear</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'remove'" />
                </v-tooltip>
            </v-toolbar>
        </v-row>
        <v-row
            v-show="!isCollapsed"
            style="margin-top:-16px;"
            class="tertiary px-2 mb-1 fill-height elevation-1"
            align="center"
            justify="space-around"
        >
            <v-col cols="12">
                <v-card-title
                    class="pa-0 text-subtitle-1"
                    v-t="'vvtProcessEditor.deletionPractise.groupOfPersons.headline'"
                />
            </v-col>
            <v-col cols="12">
                <BaseInputMultiOption
                    :disabled="!canEdit"
                    fieldName="deletionPractise"
                    childFieldName="groupOfPersons"
                    :label="$t('vvtProcessEditor.deletionPractise.groupOfPersons.label')"
                    :hint="$t('vvtProcessEditor.deletionPractise.groupOfPersons.hint')"
                    :helpModeText="$t('vvtProcessEditor.deletionPractise.groupOfPersons.helpMode')"
                    :noItemsMessage="$t('vvtProcessEditor.groupOfPerson.groupOfPersons.noItemsMessage')"
                    :items="filteredGroupOfPersonItems"
                    v-model="groupsOfPersonsModel"
                    :rowId="rowId"
                    :formScope="formScope"
                    :step="4"
                    multiple
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" v-if="usePersonalData">
                <v-card-title
                    class="pa-0 pb-3 text-subtitle-1"
                    v-t="'vvtProcessEditor.deletionPractise.personalData.headline'"
                />
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="4"
                    :i="i"
                    :rowId="rowId"
                    :items="filteredPersonalDataItems"
                    v-model="personalDataModel"
                    fieldName="deletionPractise"
                    childFieldName="personalData"
                    :label="$t('vvtProcessEditor.deletionPractise.personalData.label')"
                    :hint="$t('vvtProcessEditor.deletionPractise.personalData.hint')"
                    :helpModeText="$t('vvtProcessEditor.deletionPractise.personalData.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    clearable
                    chips
                    deletableChips
                    enable-select-all
                >
                    <template v-slot:selection="{ parent, item, selected }">
                        <v-tooltip v-if="item === Object(item)" bottom color="primary">
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-chip
                                    v-on="onTooltip"
                                    :color="getColor(item.confLevel)"
                                    :input-value="selected"
                                    class="v-chip--removable"
                                    :class="{'v-chip--disabled': !canEdit}"
                                >
                                    {{ item.title }}
                                    <v-btn v-if="canEdit" icon @click="parent.selectItem(item)" class="ml-3 mr-n3 v-chip__close">   
                                        <v-icon size="18"
                                            >mdi-close-circle</v-icon
                                        >
                                    </v-btn>
                                </v-chip>
                            </template>
                            <span
                                v-text="
                                    $t('confLevel.' + getTooltip(item.confLevel))
                                "
                            ></span>
                        </v-tooltip>
                    </template>
                </LeaDropdown>
            </v-col>
            <v-col cols="12" v-if="useDataCategories">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="4"
                    :i="i"
                    :rowId="rowId"
                    :items="usedPersonalDataCategoryItems"
                    v-model="personalDataCategoryModel"
                    fieldName="deletionPractise"
                    childFieldName="personalDataCategories"
                    :label="$t('vvtProcessEditor.deletionPractise.personalDataCategories.label')"
                    :hint="$t('vvtProcessEditor.deletionPractise.personalDataCategories.hint')"
                    :helpModeText="$t('vvtProcessEditor.deletionPractise.personalDataCategories.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    clearable
                    chips
                    deletableChips
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                <v-card-title
                    class="pa-0 text-subtitle-1"
                    v-t="'vvtProcessEditor.deletionPractise.deletionPractiseGdpr.headline'"
                />
            </v-col>
            <v-col cols="12">
                <LeaDropdown
                    :type="$getDropdownTypeFor('deletionPractise', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                    return-object
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="4"
                    :i="i"
                    :rowId="rowId"
                    :items="gdprDeletionItems"
                    v-model="deletionPractiseGdprModel"
                    fieldName="deletionPractise"
                    childFieldName="deletionPractiseGdpr"
                    :label="$t('vvtProcessEditor.deletionPractise.deletionPractiseGdpr.label')"
                    :helpModeText="$t('vvtProcessEditor.deletionPractise.deletionPractiseGdpr.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    :freetext-enabled-hint="$t('createItemHintDefault')"
                    selected-list
                    :selected-list-title="$t('vvtProcessEditor.deletionPractise.usedDeletionDeadlines')"
                >
                    <template v-slot:prepend-inner>
                        <v-icon color="success">mdi-plus</v-icon>
                    </template>
                    <template v-slot:selection="{ index }">
                        <span
                            v-if="index === 0"
                            class="primary--text text-caption pr-1"
                            >{{ deletionPractiseGdprModel.length }}
                            {{ $t('selected') }}</span
                        >
                    </template>
                    <template v-slot:item="{ item }">
                        <div class="w100">
                            {{ item.title }}
                        </div>
                    </template>
                </LeaDropdown>
            </v-col>
            <v-col cols="12">
                <LeaUpload
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="4"
                    :rowId="rowId"
                    fieldName="deletionPractise"
                    childFieldName="enclosure"
                    :label="$t('vvtProcessEditor.deletionPractise.enclosure.label')"
                    :hint="$t('vvtProcessEditor.deletionPractise.enclosure.hint')"
                    :helpModeText="$t('vvtProcessEditor.deletionPractise.enclosure.helpMode')"
                    :removeHint="$t('remove')"
                    :create="create"
                    v-model="enclosureModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
        </v-row>
        <BaseConfirmationDialog
            v-model="removeItemConfirmation"
            :title="$t('rowItem.removeConfirmation.headline')"
            :text="$t('rowItem.removeConfirmation.text')"
            :confirmationText="$t('rowItem.removeConfirmation.delete')"
            :cancelText="$t('rowItem.removeConfirmation.cancel')"
            @cancel="removeItemConfirmation = false"
            @confirm="removeItem()"
        />
        <CommentsDialog
            v-model="commentMode"
            :generalComments="generalComments"
            :internalComments="internalComments"
            @data="setCommentsToModel($event)"
            :disabled="!canEdit"
        />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModelMixin from '@/components/vvt/ModelMixin';
import ProtectionRequirements from '@/components/vvt/ProcessEditor/ProtectionRequirements';
import LeaDropdown from '@/components/Input/LeaDropdown';
import LeaUpload from '@/components/Input/LeaUpload';
import CommentsDialog from '@/components/vvt/ProcessEditor/CommentsDialog.vue';

export default {
    name: 'DeletionPractiseItem',
    mixins: [ModelMixin, ProtectionRequirements],
    components: {
        LeaDropdown,
        LeaUpload,
        CommentsDialog,
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        i: {
            type: Number,
            default: null,
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        lastIndex: {
            type: Number,
            default: null,
        },
        create: {
            type: Boolean,
            default: false,
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            highlightCollapseButton: false,
            removeItemConfirmation: false,
            commentMode: false,
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            groupsOfPersonGroupItems: 'groupsOfPersons/getItems',
            getChild: 'processingActivityModel/getChildProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            usedPersonalDataCategoryItems: 'processingActivityModel/getUsedPersonalDataCategoryItems',
            gdprDeletionItems: 'deletionPractise/getGdprItems',
            personalDataItems: 'personalData/getItems',
        }),
        deletionPractise: {
            get() {
                return this.getChild({
                    property: 'deletionPractise',
                    child: this.i,
                });
            },
            set(val) {
                this.set({
                    property: 'deletionPractise',
                    child: this.i,
                    data: val,
                });
            },
        },
        groupName() {
            const tmpItem = this.deletionPractiseGdprModel.length ? this.gdprDeletionItems.find(
                x => x.id === this.deletionPractiseGdprModel[0]
            ) : null;
            return tmpItem && tmpItem.title
                ? tmpItem.title
                : this.deletionPractiseGdprModel[0] && this.deletionPractiseGdprModel[0].title
                ? this.deletionPractiseGdprModel[0].title
                : null;
        },
        filteredGroupOfPersonItems() {
            const items = this.get('groupsOfPersons').map(x => {
                const itemId = x.group.id || x.group.clientId;
                const rowId = x.id;
                const rowClientId = x.clientId;
                const originalItem = itemId ? this.groupsOfPersonGroupItems.find(
                    x => x.id === itemId || x.clientId === itemId
                ) : {title: this.$t('N/A')};
                const item = {
                    ...originalItem,
                    ...{ id: rowId, clientId: rowClientId },
                };
                return item;
            });
            return JSON.parse(JSON.stringify(items));
        },
        groupsOfPersonsModel: {
            get() {
                return this.deletionPractise.groupsOfPersons;
            },
            set(val) {
                const merged = {
                    ...this.deletionPractise,
                    ...{ groupsOfPersons: val },
                };
                this.deletionPractise = merged;
            },
        },
        filteredPersonalDataItems() {
            const arrPersonalData = [];
            const groups = JSON.parse(
                JSON.stringify(this.get('groupsOfPersons'))
            );
            groups.forEach(group => {
                group.personalData.forEach(data => {
                    const tempItem = this.personalDataItems.find(
                        x => x.id === data || x.clientId === data
                    );
                    let exists = false;
                    if (tempItem) {
                        exists = arrPersonalData.find(
                            x =>
                                (x.id && x.id === tempItem.id) ||
                                (x.clientId && x.clientId === tempItem.clientId)
                        );
                        if (!exists) {
                            arrPersonalData.push(tempItem);
                        }
                    }
                });
            });
            return arrPersonalData;
        },
        personalDataModel: {
            get() {
                return this.getValue(this.filteredPersonalDataItems, this.deletionPractise.personalData);
            },
            set(val) {
                const data = this.setValue(this.filteredPersonalDataItems, val);
                const merged = {
                    ...this.deletionPractise,
                    ...{ personalData: data },
                };
                this.deletionPractise = merged;
            },
        },
        personalDataCategoryModel: {
            get() {
                return this.deletionPractise.personalDataCategory;
            },
            set(val) {
                const merged = {
                    ...this.deletionPractise,
                    ...{ personalDataCategory: val },
                };
                this.deletionPractise = merged;
            },
        },
        deletionPractiseGdprModel: {
            get() {
                return this.getValue(this.gdprDeletionItems, this.deletionPractise.deletionPractiseGdpr);
            },
            set(val) {
                const data = this.setValue(
                    this.gdprDeletionItems,
                    val,
                    null,
                    {
                        country: null,
                    },
                    'deletionPractise/unshiftItems'
                );
                const merged = {
                    ...this.deletionPractise,
                    ...{ deletionPractiseGdpr: data },
                };
                this.deletionPractise = merged;
            },
        },
        enclosureModel: {
            get() {
                return this.deletionPractise.enclosure;
            },
            set(val) {
                const merged = {
                    ...this.deletionPractise,
                    ...{ enclosure: val },
                };
                this.deletionPractise = merged;
            },
        },
        generalComments: {
            get() {
                return this.deletionPractise.generalComments;
            },
            set(val) {
                const merged = {
                    ...this.deletionPractise,
                    ...{ generalComments: val },
                };
                this.deletionPractise = merged;
            },
        },
        internalComments: {
            get() {
                return this.deletionPractise.internalComments;
            },
            set(val) {
                const merged = {
                    ...this.deletionPractise,
                    ...{ internalComments: val },
                };
                this.deletionPractise = merged;
            },
        },
        hasError() {
            // if (this.$validator.errors.has('deletionPractiseGroupsOfPersons' + this.rowId, this.formScope)) {
            //   return true
            // }
            return false;
        },
        hasComments() {
            return (
                !!(
                    (this.generalComments &&
                        this.generalComments.trim().length) ||
                    (this.internalComments &&
                        this.internalComments.trim().length)
                ) === true
            );
        },
        useDataCategories () {
            if (this.hasPersonalDataCategories) {
                return true;
            }
            return this.getCompanyGroupSettings('useDataCategories');
        },
        usePersonalData () {
            if (this.hasPersonalData) {
                return true;
            }
            return this.getCompanyGroupSettings('useDataFields');
        },
        hasPersonalData() {
            return this.get('groupsOfPersons').some(x => x.personalData && x.personalData.length);
        },
        hasPersonalDataCategories() {
            return this.get('groupsOfPersons').some(x => x.personalDataCategory && x.personalDataCategory.length);
        }
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setChildProperty',
            remove: 'processingActivityModel/removeDeletionPractise',
            add: 'processingActivityModel/addDeletionPractise',
            move: 'processingActivityModel/moveDeletionPractise',
        }),
        removeItem() {
            this.remove(this.i).then(this.$emit('collapseAll'));
        },
        duplicateItem() {
            this.add({
                item: this.deletionPractise,
                index: this.i + 1,
            })
                .then(this.$emit('collapseAll'))
                .then(this.collapseItem('show', this.i + 1));
        },
        moveItem(dir) {
            this.move({
                from: this.i,
                to: dir === 'up' ? this.i - 1 : this.i + 1,
            }).then(this.$emit('collapseAll'));
        },
        collapseItem(type, index) {
            this.$emit('collapse', {
                type: type,
                index: index,
            });
        },
        setCommentsToModel(val) {
            this.generalComments = val.generalComments;
            this.internalComments = val.internalComments;
        },
    },
};
</script>
