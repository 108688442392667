<template>
    <form
        @submit.prevent="validateForm(formScope)"
        :data-vv-scope="formScope"
        :ref="formScope"
    >
        <DeletionPractise
            :create="create"
            :formScope="formScope"
            @help-mode="$emit('help-mode', $event)"
        />
    </form>
</template>

<script>
import DeletionPractise from './DeletionPractise';

export default {
    name: 'Step4',
    components: {
        DeletionPractise,
    },
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        formScope: {
            type: String,
            default: null
        }
    },
};
</script>
