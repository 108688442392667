<template>
    <v-card flat tile color="secondary" class="pb-1">
        <RowHeading
            :headline="$t('vvtProcessEditor.deletionPractise.headline')"
            :button-text="$t('vvtProcessEditor.deletionPractise.add')"
            :disabled="!canEdit"
            @click:add="addRow"
        />
        <div v-if="deletionPractices && deletionPractices.length" class="pl-6">
            <DeletionPractiseItem
                v-for="(deletionPractise, i) in deletionPractices"
                :key="deletionPractise.id || deletionPractise.clientId"
                :rowId="deletionPractise.id || deletionPractise.clientId"
                :formScope="formScope"
                :i="i"
                :lastIndex="deletionPractices.length - 1"
                :create="create"
                :isCollapsed="collapseStates[i]"
                :canEdit="canEdit"
                @collapse="onCollapse($event)"
                @collapseAll="onCollapseAll()"
                @help-mode="$emit('help-mode', $event)"
            />
        </div>
        <div v-else class="ma-3">
            <v-btn
                :disabled="!canEdit"
                @click="addRow"
                color="success"
                x-large
                block
                text
                outlined
                class="btn--justify-content-start text-transform--none"><v-icon left>mdi-plus</v-icon>{{ $t('vvtProcessEditor.deletionPractise.addHint') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import RowHeading from '@/components/vvt/ProcessEditor/Row/Heading.vue';
import DeletionPractiseItem from './DeletionPractiseItem.vue';

export default {
    name: 'DeletionPractise',
    components: {
        RowHeading,
        DeletionPractiseItem,
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        create: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            collapseStates: [],
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty'
        }),
        deletionPractices() {
            return this.get('deletionPractise');
        },
        canEdit () {
            return this.get('canEdit');
        },
    },
    methods: {
        addRow() {
            this.$store
                .dispatch('processingActivityModel/addDeletionPractise')
                .then(() => {
                    this.collapseAll();
                    this.$set(this.collapseStates, 0, false);
                });
        },
        onCollapse(event) {
            if (event.type === 'hide') {
                this.$set(this.collapseStates, event.index, true);
            } else {
                this.$set(this.collapseStates, event.index, false);
            }
        },
        onCollapseAll() {
            this.collapseAll();
        },
        collapseAll() {
            for (var i = 0, len = this.deletionPractices.length; i < len; i++) {
                this.$set(this.collapseStates, i, true);
            }
        }
    },
    created() {
        this.collapseAll();
    },
};
</script>
