<template>
    <div>
        <v-dialog
            v-model="model"
            :max-width="$vuetify.breakpoint.smAndDown ? '95%' : '70%'"
        >
            <v-card>
                <v-card-title
                    class="text-h5 grey lighten-2"
                    v-t="'comments.general.headline'"
                />
                <v-card-text>
                    <v-textarea
                        :label="$t('comments.general.label')"
                        :hint="$t('comments.general.hint')"
                        v-model="generalModel"
                        auto-grow
                        persistent-hint
                        filled
                        background-color="transparent"
                        spellcheck="true"
                        :disabled="disabled"
                    />
                </v-card-text>
                <v-card-title
                    class="text-h5 grey lighten-2"
                    v-t="'comments.internal.headline'"
                />
                <v-card-text>
                    <v-textarea
                        :label="$t('comments.internal.label')"
                        :hint="$t('comments.internal.hint')"
                        v-model="internalModel"
                        auto-grow
                        persistent-hint
                        filled
                        background-color="transparent"
                        spellcheck="true"
                        :disabled="disabled"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click.stop="confirm()">{{ $t('back') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { debounce } from 'lodash';
export default {
    name: 'CommentsDialog',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        internalComments: {
            type: String,
            default: null,
        },
        generalComments: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        internalModel: {
            get() {
                return this.internalComments;
            },
            set: debounce(function(val) {
                this.$emit('data', {
                    generalComments: this.generalModel,
                    internalComments: val,
                });
            }, 500),
        },
        generalModel: {
            get() {
                return this.generalComments;
            },
            set: debounce(function(val) {
                this.$emit('data', {
                    generalComments: val,
                    internalComments: this.internalModel,
                });
            }, 500),
        },
    },
    methods: {
        confirm() {
            this.$emit('data', {
                generalComments: this.generalModel,
                internalComments: this.internalModel,
            });
            this.model = false;
        },
        cancel() {
            this.model = false;
        },
    },
};
</script>
