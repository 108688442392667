<template>
    <div style="height:100%">
        <LeaCombobox
            v-if="type === 'combobox'"
            v-bind="{...$props, ...$attrs}"
            v-on="$listeners"
        >
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </LeaCombobox>
        <LeaAutocomplete
            v-else-if="type === 'autocomplete'"
            v-bind="{...$props, ...$attrs}"
            v-on="$listeners"
        >
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </LeaAutocomplete>
        <LeaSelect
            v-else-if="type === 'select'"
            v-bind="{...$props, ...$attrs}"
            v-on="$listeners"
        >
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </LeaSelect>
    </div>
</template>

<script>

import LeaSelect from '@/components/Input/LeaSelect';
import LeaCombobox from '@/components/Input/LeaCombobox';
import LeaAutocomplete from '@/components/Input/LeaAutocomplete';
export default {
    name: 'LeaDropdown',
    props: {
        type: {
            type: String,
            default: 'select',
        }
    },
    components: {
        LeaSelect,
        LeaCombobox,
        LeaAutocomplete
    },
};
</script>
