export default {
    methods: {
        getColor(confLevel) {
            let textColor = this.$vuetify.dark ? 'white--text' : 'black--text';
            let strClass = '';
            if (!confLevel || confLevel === 0) {
                strClass = 'secondary ' + textColor;
            }else if (confLevel === 1) {
                strClass = 'grey  lighten-4 ' + textColor;
            } else if (confLevel === 2) {
                strClass = 'grey lighten-2 ' + textColor;
            } else if (confLevel === 3) {
                strClass = 'grey lighten-1 ' + textColor;
            } else if (confLevel === 4) {
                strClass = 'red lighten-3 black--text';
            } else if (confLevel === 5) {
                strClass = 'red darken-0 black--text';
            } else if (confLevel === 9) {
                strClass = 'red accent-4 yellow--text';
            }
            return strClass;
        },
        getTooltip(confLevel) {
            let tooltip = 'undefinedProtectionRequirement';
            if (confLevel === 1) {
                tooltip = 'publicProtectionRequirement';
            } else if (confLevel === 2) {
                tooltip = 'lowProtectionRequirement';
            } else if (confLevel === 3) {
                tooltip = 'mediumProtectionRequirement';
            } else if (confLevel === 4) {
                tooltip = 'highProtectionRequirement';
            } else if (confLevel === 5) {
                tooltip = 'veryHighProtectionRequirement';
            } else if (confLevel === 9) {
                tooltip = 'article9ProtectionRequirement';
            }
            return tooltip;
        },
    },
};
